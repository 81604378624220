import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Message from 'components/content/Message';
export const _frontmatter = {
  "path": "/developer/es6-new-features",
  "date": "2015-07-06",
  "title": "ES6 NEW FEATURES",
  "author": "admin",
  "tags": ["development", "javascript"],
  "featuredImage": "feature.jpg",
  "excerpt": "Arrows are a function shorthand using the => syntax (‘fat arrow’). They are syntactically similar to the related feature in C#, Java 8 and CoffeeScript. They support both expression and statement bodies. Unlike functions, arrows share the same lexical this as their surrounding code."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Message type="info" title="Scope" content="The lexical scope (this) in arrow is same as surrounding scope, unlike functions which create new scope." mdxType="Message" />
    {
      /*a class="jsbin-embed" href="http://jsbin.com/xoduwe/embed?js,console">JS Bin on jsbin.com</a*/
    }
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`/* Lexical scope (this) in arrow is same as surrounding scope,
   but function scope is a new scope */

var pets = ['cat', 'dog', 'bird', 'fish'];

var a = pets.map(p => p.length);

console.log(a);
`}</code></pre>
    {
      /*script src="http://static.jsbin.com/js/embed.min.js?3.30.1"></script*/
    }
    <h2>{`Classes`}</h2>
    <p>{`ES6 classes are simply syntactical sugar over the prototype-based Object Orientation pattern. Having a single convenient declarative form makes class patterns easier to use. Classes support prototype-based inheritance, super calls, instance and static methods and constructors.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`class Vehicle {
  name = 'Vehicle';

  constructor(doors, wheels) {
    this.doors = doors;
    this.wheels = wheels;
    this.engineState = 'stopped';
    console.log('Contructed a vehicle with '
      + this.doors + ' doors and '
      + this.wheels + ' wheels.');
  }
  startEngine() {
    this.engineState = 'started';
    console.log('Engine started ..');
  }
  stopEngine() {
    this.engineState = 'stopped';
    console.log('Engine stopped ..');
  }
  drive() {
    if(this.engineState == 'started') {
      console.log('driving ..');
    } else {
      console.log('start the engine first!');
    }
  }
  static showName() {
    console.log(this.name);
  }
  static get instance() {
    if(!Vehicle) {
      return new Vehicle();
    }
    return this;
  }
}

class Car extends Vehicle {
  name = 'car';

  constructor(doors, wheels) {
    super(doors, wheels);
  }
  static showName() {
    console.log(this.name);
  }
}

Vehicle.instance.showName();

var car = new Car(2, 4);
car.startEngine();

`}</code></pre>
    {
      /*a class="jsbin-embed" href="http://jsbin.com/yucova/embed?js,output&height:300px">JS Bin on jsbin.com</a*/
    }
    <h2>{`Let & Const`}</h2>
    <p>{`They are Block-scoped binding constructs. `}<inlineCode parentName="p">{`let`}</inlineCode>{` has block scope unlike `}<inlineCode parentName="p">{`var`}</inlineCode>{` which has lexical scope.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function run() {
  let it = "be";

  // block scope
  {
    let it = "go";
    console.log(it);  // go
  }
  console.log(it);  // be
}

run();
`}</code></pre>
    {
      /*a class="jsbin-embed" href="http://jsbin.com/dowiga/embed?js,console">JS Bin on jsbin.com</a*/
    }
    <Message type="info" title="Single Assignment" content="The constant `const` can only be assigned once and immutable. It prevents use before assignment." mdxType="Message" />
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function run() {
  const it = "be";

  it = "go"; // error: trying to override 'it' constant

  console.log(it);
}

run();
`}</code></pre>
    {
      /*a class="jsbin-embed" href="http://jsbin.com/tejiwi/embed?js,console">JS Bin on jsbin.com</a*/
    }
    <h2>{`Symbols`}</h2>
    <p>{`Symbols enable access control for object state. A symbol is a unique and immutable data type and may be used as an identifier for object properties.
Symbols are a new primitive type. Optional `}<inlineCode parentName="p">{`name`}</inlineCode>{` parameter used in debugging - but is not part of identity. Symbols are unique, but not private since they are exposed via reflection features like `}<inlineCode parentName="p">{`Object.getOwnPropertySymbols`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var count = Symbol('count');

class People {
    constructor() {
        this[count] = 0;
    }

    add(item) {
        this[this[count]] = item;
        this[count]++;
    }

    static sizeOf(instance) {
        return instance[count];
    }

}

var people = new People();

console.log(Symbol("count") === Symbol("count")); // false
console.log(People.sizeOf(people)); // 0

people.add('John Smith');

console.log(People.sizeOf(people)); // 1
`}</code></pre>
    {
      /*a class="jsbin-embed" href="http://jsbin.com/fumoso/embed?js,console">JS Bin on jsbin.com</a*/
    }
    <h2>{`Generators`}</h2>
    <p>{`Generators are functions that can be paused(exit) and resumed(re-enter).
Their context (variable bindings) will be saved across re-entrances.
These can be helpful for iterators, asynchronous programming, etc.`}</p>
    <Message type="info" title="Execution" content="Calling a generator function does not execute its body immediately; an iterator object for the function is returned instead.
    When the iterator's `next()` method is called, the generator function's body is executed until the first `yield` expression." mdxType="Message" />
    {
      /*a class="jsbin-embed" href="http://jsbin.com/zobopi/embed?js,console">JS Bin on jsbin.com</a*/
    }
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var fib = fibonacci();

function* fibonacci(){
  let val1 = 0,
      val2 = 1,
      swap;

  yield val1;
  yield val2;

  while (true){
    swap = val1 + val2;
    val1 = val2;
    val2 = swap;

    yield swap;
  }
}

function run(){
  let i = 0;

  while ( i < 10 ) {
    console.log(fib.next().value);
    i++;
  }
}

run();
`}</code></pre>
    <h2>{`Iterators & For..of`}</h2>
    <p>{`Iterator enables JavaScript objects to define custom iteration like CLR IEnumerable or Java Iterable.
This will generalize `}<inlineCode parentName="p">{`for..in`}</inlineCode>{` to custom iterator-based iteration with `}<inlineCode parentName="p">{`for..of`}</inlineCode>{`.`}</p>
    <Message type="info" title="@@iterator" content="In order to be iterable, an object must implement the `@@iterator` method, meaning that the object (or one of the objects up its prototype chain) must have a property with a `Symbol.iterator`." mdxType="Message" />
    {
      /*a class="jsbin-embed" href="http://jsbin.com/qisuco/embed?js,console">JS Bin on jsbin.com</a*/
    }
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var foo = "foo",
    iter1, iter2, iter3, iter4,
    iterator;

iterator = foo[Symbol.iterator]();

iter1 = iterator.next();
iter2 = iterator.next();
iter3 = iterator.next();
iter4 = iterator.next();

console.log(iter1); // done: false, value: "f"
console.log(iter2); // done: false, value: "o"
console.log(iter3); // done: false, value: "o"
console.log(iter4); // done: true, value: undefined
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`for...of`}</inlineCode>{` statement creates a loop Iterating over iterable objects (including Array, Map, Set, arguments object and so on),
invoking a custom iteration hook with statements to be executed for the value of each distinct property.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var fib = fibonacci();

function* fibonacci(){
  let val1 = 0,
      val2 = 1,
      swap;

  yield val1;
  yield val2;

  while (true){
    swap = val1 + val2;
    val1 = val2;
    val2 = swap;

    yield swap;
  }
}

for (var n of fib) {
  // truncate the sequence at 1000
  if (n > 1000)
    break;
  console.log(n);
}

run(); // 1 1 2 3 5 8 13 21 34 55 89 144 233

`}</code></pre>
    <h2>{`Promises`}</h2>
    <p>{`Promises are a library for asynchronous programming. Promises are a first-class representation of a value that may be made available in the future.
Promises are used in many existing JavaScript libraries.`}</p>
    <Message type="info" title="ES6 Promise API" content="The de-facto standard for JavaScript promises is called `Promises/A+`. ES6 Promise API follows this standard." mdxType="Message" />
    <p>{`Promises are a pattern that helps with asynchronous programming, functions that return their results asynchronously.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function timeout(duration = 0) {
    return new Promise((resolve, reject) => {
      setTimeout(resolve, duration);
      console.log('Timed out.. ' + duration);
    })
}

var p = timeout(1000).then(() => {
    return timeout(2000);
}).then(() => {
    throw new Error("uh oh!");
}).catch(err => {
    return Promise.all([timeout(100), timeout(200)]);
});

`}</code></pre>
    {
      /*a class="jsbin-embed" href="http://jsbin.com/suhoyag/embed?js,console">JS Bin on jsbin.com</a*/
    }
    <h2>{`Destructuring`}</h2>
    <p>{`The destructuring assignment syntax is a JavaScript expression that makes it possible to extract data from arrays or objects using a syntax that mirrors the construction of array and object literals.`}</p>
    <Message type="info" title="Syntax" content="```[a, b] = [1, 2]<br>
    [a, b, ...rest] = [1, 2, 3, 4, 5]<br>
    {a, b} = {a:1, b:2}```" mdxType="Message" />
    <p>{`This capability is similar to features present in languages such as Perl and Python.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var fruits = ["apple", "orange", "banana"];

/* without destructuring
var apple   = fruits[0];
var orange   = fruits[1];
var banana = fruits[2];
*/

// with destructuring
var [apple, orange, banana] = fruits;

console.log(apple); // "apple"
console.log([orange, banana]);// ["orange", "banana"]
`}</code></pre>
    {
      /*a class="jsbin-embed" href="http://jsbin.com/sicura/embed?js,console">JS Bin on jsbin.com</a*/
    }
    <h2>{`Modules`}</h2>
    <p>{`A module is a self-contained unit of code, which is usually stored in a file. In ES6, modules get language-level support for component definition.
There are two popular for JavaScript module loaders.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`CommonJS:`}</strong>{`" Used heavily in `}<inlineCode parentName="li">{`Node.js`}</inlineCode><ul parentName="li">
          <li parentName="ul">{`Syntactically compact`}</li>
          <li parentName="ul">{`Loads synchronously`}</li>
          <li parentName="ul">{`Used by the server`}</li>
        </ul></li>
      <li parentName="ul"><strong parentName="li">{`AMD:`}</strong>{`" Used by `}<inlineCode parentName="li">{`RequireJS`}</inlineCode><ul parentName="li">
          <li parentName="ul">{`Syntactically complex. Enables AMD to work without compilation.`}</li>
          <li parentName="ul">{`Loads asynchronously`}</li>
          <li parentName="ul">{`Used by the browser`}</li>
        </ul></li>
    </ul>
    <Message type="info" title="Import & Export" content="You have to use the `export` keyword to explicitly make it available. And use `import` keyword to use the module." mdxType="Message" />
    <h3>{`libs/math.js`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`export function sum(x, y) {
  return x + y;
}
export var pi = 3.141593;
`}</code></pre>
    <h3>{`app.js`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import * as math from "lib/math";

alert("2π = " + math.sum(math.pi, math.pi));
`}</code></pre>
    <h2>{`Math + Number + String + Object APIs`}</h2>
    <p>{`ES6 has many new library additions, including core `}<inlineCode parentName="p">{`Math`}</inlineCode>{` libraries, `}<inlineCode parentName="p">{`Array conversion`}</inlineCode>{` helpers, and `}<inlineCode parentName="p">{`Object.assign`}</inlineCode>{` for copying.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`Number.EPSILON; // fixes decimal fractions, rounding errors
Number.isInteger(Infinity); // false
Number.isFinite(Infinity) // false
Number.isNaN("NaN"); // false
Number.isSafeInteger(Infinity); // false

Math.acosh(3); // 1.762747174039086
Math.hypot(3, 4); // 5
Math.imul(Math.pow(2, 32) - 1, Math.pow(2, 32) - 2); // 2

"abcde".includes("cd"); // true
"abc".repeat(3); // "abcabcabc"

var obj = { a: 1 };
var copy = Object.assign({}, obj);
console.log(copy); // { a: 1 }
`}</code></pre>
    <Message type="info" title="Global functions" content="Four number-related functions are already available as global functions and have been added to Number, as methods: `isFinite`, `isNaN`, `parseFloat` and `parseInt`." mdxType="Message" />
    <p>{`The `}<inlineCode parentName="p">{`Object.assign()`}</inlineCode>{` method only copies enumerable and own properties from a source object to a target object.
It uses [`}{`[Get]`}{`] on the source and [`}{`[Put]`}{`] on the target, so it will invoke `}<inlineCode parentName="p">{`getters`}</inlineCode>{` and `}<inlineCode parentName="p">{`setters`}</inlineCode>{`.
Therefore it assigns properties versus just copying or defining new properties. `}</p>
    <Message type="warn" title="Exceptions on Object" content="Note that Object.assign() does not throw on `null` or `undefined` source values." mdxType="Message" />
    {
      /*a class="jsbin-embed" href="http://jsbin.com/xusuwi/embed?js,console">JS Bin on jsbin.com</a*/
    }
    <h2>{`Default + Rest + Spread`}</h2>
    <p>{`ES6 gives us a way to set `}<inlineCode parentName="p">{`default`}</inlineCode>{` function parameters. Any parameters with a default value are considered to be optional.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function sum(number, increment = 1) {
  return number + increment;
}

sum(2, 2); // 4
sum(2);    // 3
`}</code></pre>
    <p><inlineCode parentName="p">{`Rest`}</inlineCode>{` parameters are indicated by three dots `}<inlineCode parentName="p">{`…`}</inlineCode>{` preceding a parameter. This named parameter becomes an array which contains the rest of the parameters.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function sum(...numbers) {
  var result = 0;
  numbers.forEach(function (number) {
    result += number;
  });
  return result;
}
sum(1); // 1
sum(1, 2, 3, 4, 5); // 15
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`spread`}</inlineCode>{` is closely related to rest parameters, because of … (three dots) notation. It allows to split an array to single arguments which are passed to the function as separate arguments.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function sum(a, b, c) {
  return a + b + c;
}

var args = [1, 2, 3];
sum(...args); // 6
`}</code></pre>
    <div className="info">
  ## ...
  Instead using an apply function, we can just type `...args` and pass all array argument separately.
    </div>
    <h2>{`Proxies`}</h2>
    <p>{`In ES6 proxies enable you to intercept and customize operations performed on objects including accessing properties. Can be used for interception, object virtualization, logging/profiling, etc.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// Proxying a normal object
var target = {};
var handler = {
  get: function (receiver, name) {
    return 'Hello, ' + name;
  }
};

var p = new Proxy(target, handler);
p.world === "Hello, world!";

// Proxying a function object
var target = function () { return "I am the target"; };
var handler = {
  apply: function (receiver, ...args) {
    return "I am the proxy";
  }
};

var p = new Proxy(target, handler);
p() === "I am the proxy";
`}</code></pre>
    <h3>{`Methods of handler object`}</h3>
    <p>{`The handler object is a placeholder object which contains traps for Proxy.`}</p>
    <Message type="info" title="Traps" content="All traps are optional. If a trap has not been defined, the default behavior is to forward the operation to the target." mdxType="Message" />
    <ul>
      <li parentName="ul">{`getPrototypeOf()`}</li>
      <li parentName="ul">{`setPrototypeOf()`}</li>
      <li parentName="ul">{`isExtensible()`}</li>
      <li parentName="ul">{`preventExtensions()`}</li>
      <li parentName="ul">{`getOwnPropertyDescriptor()`}</li>
      <li parentName="ul">{`defineProperty()`}</li>
      <li parentName="ul">{`has()`}</li>
      <li parentName="ul">{`get()`}</li>
      <li parentName="ul">{`set()`}</li>
      <li parentName="ul">{`deleteProperty()`}</li>
      <li parentName="ul">{`enumerate()`}</li>
      <li parentName="ul">{`ownKeys()`}</li>
      <li parentName="ul">{`apply()`}</li>
      <li parentName="ul">{`apply()`}</li>
    </ul>
    <p>{`More new features will be discussed in `}<a parentName="p" {...{
        "href": "/developer/es6-advanced-features"
      }}>{`ES6 Advanced Features`}</a>{` post.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      